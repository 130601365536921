<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import User from '../models/User';

const router = useRouter();
const user = ref(null);
const username = ref("");
const userLevel = ref("");
const berautisticBalance = ref(0);
const honeyCombBalance = ref(0);
const bitBearsBalance = ref(0);
const evmAddressesList = ref([]);
const solAddressesList = ref([]);
const userInstance = new User();
const twitterUser = ref(true);
const newUsername = ref("");
const errorMessageUserUpdate = ref("");
const errorMessageEnterRaffle = ref("");
const errorMessageLinkAddress = ref("");
const successLinkedAddress = ref("");
const enterRaffleTweet = ref("");
const enteredRaffle = ref("");
const isVisible = ref(true);

const userLevelClass = computed(() => {
    switch (userLevel.value) {
        case "Poor":
        case "Have fun staying poor":
            return "poor";
        case "Boomer":
        case "Whatever McDonalds worker":
            return "boomer";
        case "Degen":
        case "Chief BERA executive":
            return "degen";
        case "Degen whale":
        case "Chairman of BERA":
            return "degen-whale";
        case "Degen maximus":
        case "Big kahuna":
            return "degen-maximus";
        default: return "";
    }
});
const eyeIconClass = computed(() => {
      return isVisible.value ? 'fa fa-eye' : 'fa fa-eye-slash';
    });

const toggleVisibility = () => {
      isVisible.value = !isVisible.value;
    };

const shortenAddressEvm = (address) => {
    return address;
};
const shortenAddressSol = (address) => {
    return address;
};

const shortenedEvmAddressesList = computed(() => {
    return evmAddressesList.value.map(address => shortenAddressEvm(address));
});

const shortenedSolAddressesList = computed(() => {
    return solAddressesList.value.map(address => shortenAddressSol(address));
});

const getUser = async () => {
    user.value = await userInstance.getUser();
    if (user.value == null) {
        router.push({ name: 'Home' });
    }
    if (user.value && user.value.nft_balances) {
        userLevel.value = user.value.level;
        const balances = user.value.nft_balances;
        evmAddressesList.value = user.value.evm_addresses ? user.value.evm_addresses.map(addrObj => addrObj.address) : [];
        solAddressesList.value = user.value.solana_addresses ? user.value.solana_addresses.map(addrObj => addrObj.address) : [];
        username.value = user.value.username === '' ? shortenAddressSol(user.value.address) : user.value.username;
        bitBearsBalance.value = balances.find(nft => nft.collection === 'Bit Bears')?.balance || 0;
        honeyCombBalance.value = balances.find(nft => nft.collection === 'Honey Comb')?.balance || 0;
        berautisticBalance.value = balances.find(nft => nft.collection === 'Berautistics')?.balance || 0;
        if (user.value.twitter_username) {
            twitterUser.value = '@' + user.value.twitter_username;
        }
        else {
            twitterUser.value = null;
        }
    } else {
        router.push({ name: 'Home' });
    }
};

// const deleteUser = async () => {
//     try {
//         await userInstance.deleteUser();
//         router.push({ name: 'Home' });
//     } catch (error) {
//         console.log('error delete user')
//         errorMessageUserUpdate.value = 'Something was wrong deleting user'
//     }
// };

const logout = async () => {
    try {
        await userInstance.logout();
        router.push({ name: 'Home' });
    } catch (error) {
        console.log('logout failed')
        errorMessageUserUpdate.value = 'Logout failed'
    }
};

const linkEvmAddress = async () => {
    try {
        const address = await userInstance.addEvmAddress();
        evmAddressesList.value.push(address);
        successLinkedAddress.value = "Address linked successfully";
        errorMessageLinkAddress.value = "";
        await getUser();    // Update user with new data
    } catch (error) {
        errorMessageLinkAddress.value = error.message;
    }
};

const linkSolAddress = async () => {
    try {
        const address = await userInstance.addSolAddress();
        solAddressesList.value.push(address);
        successLinkedAddress.value = `Address ${address} linked successfully`;
        errorMessageLinkAddress.value = "";
        await getUser();    // Update user with new data
    } catch (error) {
        errorMessageLinkAddress.value = error.message;
    }
};

const linkTwitterAccount = async () => {
    window.location.href = `${process.env.VUE_APP_API_URL}/v1/oauth/twitter?accessToken=${localStorage.getItem('accessToken')}`;
};

const updateUsername = async () => {
    errorMessageUserUpdate.value = "";
    try {
        if (newUsername.value.trim() === "") {
            errorMessageUserUpdate.value = "Username cannot be empty";
            return;
        }
        // Validate the username input with the regex ^[a-zA-Z0-9][a-zA-Z0-9._@#$&-]{2,28}[a-zA-Z0-9]$
        if (!/^[a-zA-Z0-9][a-zA-Z0-9._@#$&-]{2,28}[a-zA-Z0-9]$/.test(newUsername.value)) {
            errorMessageUserUpdate.value = 'Username must be between 4 and 30 characters and can only contain letters, numbers, and special characters . _ @ # $ & -';
            return;
        }
        await userInstance.updateUsername(newUsername.value);
        username.value = newUsername.value;
        newUsername.value = "";
    } catch (error) {
        errorMessageUserUpdate.value = error.message;
    }
};

const enterRaffle = async () => {
    if (twitterUser.value == null) {
        errorMessageEnterRaffle.value = "Ser can you read? You need to connect your twitter account";
        return;
    }
    try {
        await userInstance.enterRaffle(enterRaffleTweet.value);
        enteredRaffle.value = true;
        errorMessageEnterRaffle.value = "";
    } catch (error) {
        errorMessageEnterRaffle.value = error.message;
    }
};

onMounted(async () => {
    await getUser();
});

</script>

<template>
    <div id="app" class="flex flex-col h-auto !my-24 ">
        <div class="flex-grow flex justify-center items-center max-w-full ">
            <div class=" mob flex flex-col gap-8 w-auto h-auto max-w-full">
                <div class="card rounded-md">
                    <div class="top-section">
                        <div class="left">
                            <div class="title">BERA PASSPORT</div>
                            <div class="subtitle">GAY MEMBERSHIP</div>
                        </div>
                        <div class="right">
                            <div class="rainbow"></div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div class="bottom-section">
                        <div class="profile-picture"></div>
                        <div class="details">
                            <div class="row">
                                <p class="text-lg">
                                    Ser: <span class="name">{{ username }}</span>
                                </p>
                            </div>
                            <div class="row">
                                <p class="text-lg">
                                    Bera score: <span :class="['score', 'text-sm', userLevelClass]">{{ userLevel
                                        }}</span>
                                </p>
                            </div>
                            <div class="row flex justify-between">
                                <p class="text-lg">NFT holdings:</p>
                                <div class="mt-1" @click="toggleVisibility">
                                    <!-- Controlamos la clase del ícono dinámicamente -->
                                    <i :class="eyeIconClass" aria-hidden="true" style="cursor: pointer;" title="Hide/Show balances"></i>
                                </div>
                            </div>

                            <div class="flex gap-2">
                                <div class="badge relative">
                                    <div class="badge-background badge-berautistics transition-all duration-300"
                                        :class="{ 'grayscale': berautisticBalance == 0, 'grayscale-0': berautisticBalance > 0 }">
                                    </div>
                                    <!-- Mostramos u ocultamos el span según la visibilidad -->
                                    <span v-if="isVisible" class="badge-number absolute z-10">{{ berautisticBalance
                                        }}</span>
                                </div>
                                <div class="badge relative">
                                    <div class="badge-background badge-beras transition-all duration-300"
                                        :class="{ 'grayscale': bitBearsBalance == 0, 'grayscale-0': bitBearsBalance > 0 }">
                                    </div>
                                    <span v-if="isVisible" class="badge-number absolute z-10">{{ bitBearsBalance
                                        }}</span>
                                </div>
                                <div class="badge relative">
                                    <div class="badge-background badge-honeycomb transition-all duration-300"
                                        :class="{ 'grayscale': honeyCombBalance == 0, 'grayscale-0': honeyCombBalance > 0 }">
                                    </div>
                                    <span v-if="isVisible" class="badge-number absolute z-10">{{ honeyCombBalance
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="add-address" class="w-full flex-row mb-5 mt-8 card rounded-md ">
                    <div class="w-full flex justify-center sm:gap-4 md:gap-4 gap-6">
                        <button @click="linkEvmAddress"
                            class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 sm:text-sm text-md h-14">
                            ADD EVM ADDRESS
                        </button>
                        <button @click="linkSolAddress"
                            class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 sm:text-sm text-md h-14">
                            ADD SOL ADDRESS
                        </button>
                    </div>
                    <div class="my-3" style="display:grid">
                        <a href="#sol-addresses"
                            class="sm:w-full w-3/4 mt-10 bg-blue-300 text-black px-6 py-4 rounded font-bold hover:bg-blue-400 transition duration-300 text-md disabled:opacity-50 ">
                            See your linked addresses
                        </a>
                    </div>
                    <p v-if="errorMessageLinkAddress" class="text-center text-red-500">{{ errorMessageLinkAddress }}</p>
                    <p v-if="successLinkedAddress" class="text-center text-green-500">{{ successLinkedAddress }}</p>
                </div>


                <div id="raffle" class="card rounded-md border p-4 flex flex-col justify-centerr">
                    <h2 class="text-lg text-center py-5">Join the raffle</h2>
                    <p class="text-center font-medium">We are raffling some NFTs if you follow the steps below:</p>
                    <div class="my-4">
                        <ul class="text-left px-12 my-4">
                            <li>
                                <p class="text-sm">1. Tweet a screenshot of your Bera Passport.</p>
                            </li>
                            <li>
                                <p class="text-sm my-2">2. Connect your twitter account.</p>
                            </li>
                            <li>
                                <p class="text-sm">3. Paste the link to your tweet below.</p>
                            </li>
                            <li>
                                <p class="text-sm">4. Hold at least 10000 $BERA.</p>
                            </li>
                            <li>
                                <p class="text-sm">5. Click join.</p>
                            </li>
                        </ul>
                    </div>
                    <button @click="linkTwitterAccount" :disabled="twitterUser != null"
                        class="w-full mt-10 bg-blue-300 text-black px-6 py-2 rounded font-bold hover:bg-blue-400 transition duration-300 text-md h-12 disabled:opacity-50 disabled:cursor-not-allowed">
                        <span v-if="twitterUser == null">Connect Twitter</span>
                        <span v-if="twitterUser != null">{{ twitterUser }}</span>
                    </button>

                    <div class="grid grid-cols-12 gap-4 my-20 w-full items-center">
                        <div class="col-span-4">
                            <button @click="enterRaffle"
                                class="bg-orange-400 text-white px-6 py-2 h-12 rounded font-bold hover:bg-orange-500 transition duration-300 text-md">
                                Join raffle
                            </button>
                        </div>
                        <div class="col-span-8">
                            <input type="text" v-model="enterRaffleTweet"
                                placeholder="https://x.com/Bera_Coin/status/1796280080932438237"
                                class="w-full border-2 border-black px-4 py-3 h-12 text-base text-center" />
                        </div>
                    </div>
                    <p v-if="errorMessageEnterRaffle" class="text-center text-red-500">{{ errorMessageEnterRaffle }}</p>
                    <p v-if="enteredRaffle" class="text-center text-green-500">Joined the raffle. Gl ser!</p>
                </div>


                <div id="edit-user" class="card rounded-md border p-4 flex flex-col justify-center">
                    <h2 class="text-lg text-center">Change username</h2>
                    <div class=" flex flex-row flex-wrap justify-around my-10 mx-10 p-8 gap-4">
                        <div class="flex flex-col">
                            <label class="">Current username: {{ username }}</label>
                            <input type="text" v-model="newUsername" placeholder="New username"
                                class="username-input border-2 border-black px-4 pb-3 pt-8 text-align: center" />
                        </div>
                        <button @click="updateUsername"
                            class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-md">
                            Update
                        </button>
                    </div>
                    <p v-if="errorMessageUserUpdate" class="text-center text-red-500">{{ errorMessageUserUpdate }}
                    </p>
                </div>


                <div id="logout" class="card rounded-md border p-4 flex justify-center gap-6">
                    <button @click="logout"
                        class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-lg">
                        Logout
                    </button>
                    <!-- <button @click="deleteUser"
                        class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-lg">
                        Delete user
                    </button> -->
                    <p v-if="errorMessageUserUpdate" class="text-center text-red-500">{{ errorMessageUserUpdate }}</p>
                </div>


                <div id="evm-addresses" v-if="evmAddressesList.length > 0"
                    class="card rounded-md border p-4 flex justify-center">
                    <div class="flex flex-col space-y-2 max-h-[150px] overflow-y-auto text-center">
                        <h3 class="text-center text-lg">EVM Addresses</h3>
                        <p v-for="address in shortenedEvmAddressesList" :key="address"
                            class="bg-orange-400 text-white py-2 text-xsm rounded font-semibold hover:bg-orange-500 transition duration-300 w-full px-3 text-center">
                            <span class="p-1 w-full inline-block break-all">{{ address }}</span>
                        </p>
                    </div>
                </div>


                <div id="sol-addresses" class="w-full flex justify-around mb-5 mt-8 card rounded-md sm:gap-3 gap-6 ">
                    <div v-if="solAddressesList.length > 0"
                        class="flex flex-col space-y-2 max-h-[150px] overflow-y-auto text-center">
                        <h3 class="text-center text-lg">SOL Addresses</h3>
                        <p v-for="address in shortenedSolAddressesList" :key="address"
                            class="bg-orange-400 text-white py-2 text-xsm rounded font-semibold hover:bg-orange-500 transition duration-300 w-full px-3 text-center">
                            <span class="p-1 w-full inline-block break-all">{{ address }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
h2 {
    font-weight: bold;
}

.name {
    font-weight: bold;
    font-size: 1.5em;
    /* Fuente divertida */
    color: #f97316;
    /* Un color alegre */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.card {
    margin: 2%;
    background-color: white;
    padding: 23px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
    max-width: 603px;
    width: 100%;

}

.top-section,
.bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    flex: 1;
    margin-right: 8px;
}

.right {
    display: flex;
    align-items: center;
}

.title {
    font-size: 40px;
    font-weight: bold;
    color: black;
}

.subtitle {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.rainbow {
    width: 100px;
    aspect-ratio: 70 / 45;
    background: linear-gradient(hotpink 12.5%, red 0 25%, orange 0 37.5%, yellow 0 50%, green 0 62.5%, darkturquoise 0 75%, blue 0 87.5%, indigo 0);
}

.separator {
    border-top: 10px solid black;
    margin: 10px 0;
}

.profile-picture {
    width: 150px;
    height: 170px;
    background-image: url('https://beracoin.app/images/main.png');
    background-size: cover;
    border-radius: 5px;
    margin-right: 20px;
}

.details {
    flex: 1;
    margin-left: 10px;
}

.row {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.badges {
    display: flex;
    gap: 5px;
}

.badge-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
}

.badge {
    width: 75px;
    height: 75px;
    background-image: url('https://via.placeholder.com/30');
    background-size: cover;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.badge-berautistics {
    background-image: url('https://i.seadn.io/s/raw/files/cc32f7b11fed38a8c2753d78c3118c2f.png?auto=format&dpr=1&w=512');
}

.badge-yeet {
    background-image: url('https://i.seadn.io/s/raw/files/d82d6a910cc9b5df5e35436103d2cdf2.png?auto=format&dpr=1&w=512');
}

.badge-beras {
    background-image: url('https://i.seadn.io/gcs/files/132192066a920269bdff84178f6a2045.jpg?auto=format&dpr=1&w=512');
}

.badge-honeycomb {
    background-image: url('https://i.seadn.io/s/raw/files/f903171253f9df3c304226e9edb75896.jpg?auto=format&dpr=1&w=512')
}

.badge-number {
    right: -5px;
    bottom: -5px;
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    color: black;
    border-radius: 50%;
    min-width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2em;
}

@media (max-width: 600px) {
    .profile-picture {
        width: 100px;
        height: 120px;
        margin-right: 10px;
    }

    .mob {
        padding: 1% !important;
        margin-top: 105px;
        margin-left: 5%;
        margin-right: 5%;
        max-width: -webkit-fill-available;
    }

    .badge {
        width: 45px;
        height: 45px;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .card {
        margin: 0%;
        background-color: white;
        padding: 20px;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
        max-width: 532px;
        width: 100%;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }

    .badge-number {
        top: 30px;
        left: 28px;
        position: absolute;
        background-color: white;
        font-weight: 300;
        font-size: 18px;
        color: black;

        /* Nuevas propiedades */
        border-radius: 50%;
        min-width: 1em;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1em;
        line-height: 1;
    }



}

.score {
    padding: 2px 5px;
    border-radius: 3px;
}

.score.poor {
    color: white;
    background-color: gray;
}

.score.boomer {
    color: black;
    background-color: lightblue;
}

.score.degen {
    color: white;
    background-color: blue;
}

.score.degen-whale {
    color: white;
    background-color: purple;
}

.score.degen-maximus {
    color: white;
    background-color: red;
}
</style>