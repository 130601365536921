import { ethers } from "ethers";
import SolanaRPC from './SolanaRPC';

function arrayBufferToHex(buffer) {
    const byteArray = new Uint8Array(buffer);
    return byteArray.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
}

export default class User {
    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.solanaAddress = '';
        this.shortenedAddress = '';
       
    }

    async fetchWithAuth(url, options = {}) {
        const accessToken = localStorage.getItem('accessToken');
    
        // Set up headers and options
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            ...options.headers
        };
        
        const response = await fetch(url, {
            ...options,
            headers
        });
    
        if (response.status === 401) {
            // This is normally because the token is invalid
            // Force logout so that token refreshes
            await this.logout();
            let loggedIn = await this.login();
           
            // Retry the request
            if (loggedIn) {
                return this.fetchWithAuth(url, options);
            }
        }
    
        return response;
    }

    async getBalance(address) {
        try {
            const BERACOIN_MINT = 'Hiqq4Kba2dawtgCtoysXKirk1KgBMSiT71FtMq76Aht';

            const rpc = new SolanaRPC([
                // 'https://api.mainnet-beta.solana.com',
                // 'https://solana-api.projectserum.com',
                'https://rpc.ankr.com/solana'
            ], 'confirmed');
            const solBalance = await rpc.getBalance(address);
            console.log(`SOL Balance: ${solBalance}`);

            const beracoinBalance = await rpc.getBeracoinBalance(address, BERACOIN_MINT);
            console.log(`Beracoin Balance: ${beracoinBalance}`);
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    async getNonce() {
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_GET_NONCE}`;
        const response = await this.fetchWithAuth(url, {
            method: 'GET',
            mode: 'cors',
        });

        if (response.ok) {
            const data = await response.json();
            return data; // Asegúrate de que data tenga la propiedad 'nonce'
        } else {
            console.error('Failed to get nonce:', response.statusText);
            return null;
        }

    }

    async addEvmAddress() {
        console.log(window.ethereum.isMetaMask)
       
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
    
            const res = await this.getNonce();
            if (!res || !res.nonce) {
                console.error('Failed to get nonce');
                return;
            }
            const nonce = res.nonce;
            let signature = await signer.signMessage(nonce);
            const signatureHexString = ethers.utils.hexlify(ethers.utils.arrayify(signature));
    
            const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_ADD_EVM_ADDRESS}`;
            // Get access token from local storage
            const response = await this.fetchWithAuth(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({
                    address: address,
                    nonce: nonce,
                    signature: signatureHexString,
                }),
            });
            if (response.status == 201) {
                console.log('Successfully added address');
                return address;
            }
            else if (response.status == 409) {
                throw new Error('Address already existent');
            }
            else {
                const data = await response.json();
                console.error('Failed to add address:', data.message);
                throw new Error(data.message);
            }
        
    }

    async addSolAddress() {
        const res = await this.getNonce();
        if (!res || !res.nonce) {
            console.error('Failed to get nonce');
            return;
        }
        await window.solana.connect();
        const nonce = res.nonce;
        let signature = await window.solana.signMessage(new TextEncoder().encode(nonce));
        let signatureHexString = arrayBufferToHex(signature.signature);

        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_ADD_SOL_ADDRESS}`;
        const response = await this.fetchWithAuth(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                address: window.solana.publicKey.toString(),
                nonce: nonce,
                signature: signatureHexString
            }),
        });

        if (response.status == 201) {
            console.log('Successfully added address');
            return window.solana.publicKey.toString();
        }
        else if (response.status == 409) {
            throw new Error('Address already existent');
        }
        else {
            const data = await response.json();
            console.error('Failed to add address:', data.message);
            throw new Error(data.message);
        }
    }

    async userExists() {
        // Check if a user with this address is already registered
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_GET_NONCE_BY_ADDRESS}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                address: window.solana.publicKey.toString(),
            }),
        });

        if (response.ok) {
            return true;
        }
        else if (response.status === 404) {
            return false;
        }
        else {
            return false;
        }
    }

    async getNonceByAddress() {
        // Get the nonce for a user with this address
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_GET_NONCE_BY_ADDRESS}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body:JSON.stringify({ 
                address : window.solana.publicKey.toString()
            }),  
        });

        if (response.ok) {
            const data = await response.json();
            return data.nonce;
        }
        else if (response.status === 404) {
            return null;
        }
        else {
            const responseJson = await response.json();
            throw new Error(responseJson.message);
        }
    }

    async updateUsername(username) {
        // Update the user's username
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_USER}`;
        const response = await this.fetchWithAuth(url, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({
                username: username
            }),
        });

        if (response.ok) {
            return true;
        }
        else {
            const responseJson = await response.json();
            throw new Error(responseJson.message);
        }
    }

    async deleteUser() {
        // Delete the user's account
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_USER}`;
        const response = await this.fetchWithAuth(url, {
            method: 'DELETE',
            mode: 'cors',
        });

        if (response.ok) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return true;
        }
        else {
            const responseJson = await response.json();
            throw new Error(responseJson.message);
        }
    }

    async logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return true;
    }

    async registerUser(username) {
        // Register a new user.
        // If a user with this address is already registered, the server will return a 409 status code along with the user's nonce
        // The signed nonce can be then used to authenticate to the server
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_REGISTER}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                address: window.solana.publicKey.toString(),
                username: username,
            }),
        });

        if (response.ok) {
            console.log('User connected successfully');
            const data = await response.json();
            return data;
        }
        else {
            // Throw an error with the text from the "message" field of the response
            const data = await response.json();
            throw new Error(data.message);
        }
    }

    async login(nonce) {
        if (nonce == null || nonce == undefined || nonce == '') {
            // Get the nonce from the server
            nonce = await this.getNonceByAddress();
        }
        // Authenticate the current user
        // Requires the user to sign the nonce with their Solana wallet
        let signature = await window.solana.signMessage(new TextEncoder().encode(nonce));
        let signatureHexString = arrayBufferToHex(signature.signature);
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_LOGIN}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                address: window.solana.publicKey.toString(),
                nonce: nonce,
                signature: signatureHexString
            }),
        });

        if (response.ok) {
            const data = await response.json();
            this.storeTokenOnLocalStorage(data.accessToken, data.refreshToken);
            return;
        }
        else {
            const response = await response.json();
            throw new Error(response.message);
        }
    }

    async enterRaffle(tweetUrl) {
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_ENTER_RAFFLE}`;
        const response = await this.fetchWithAuth(url, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': this.csrfToken
            },
            mode: 'cors',
            body: JSON.stringify({
                tweet_url: tweetUrl
            }),
        });

        if (response.ok) {
            console.log('Successfully entered raffle');
            return true;
        }
        else {
            const responseJson = await response.json();
            throw new Error(responseJson.message);
        }
    }

    storeTokenOnLocalStorage(accessToken, refreshToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    }
   
    async getUser() {
        // Fetch the user's data from the server
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URL_USER}`;
        const response = await this.fetchWithAuth(url, {
            method: 'GET',
            mode: 'cors'
        });

        if (response.ok) {
            console.log('Successfully fetched user');
            const data = await response.json();
            return data;
        } 
        if (response.status === 422) {
            await this.login();
        }
        else {
            console.error('Failed to fetch user:', response.statusText);
            return null;
        }
    }

    async connectSolanaWallet(router = '') {
        if (window.solana) {
            try {
                await window.solana.connect();
                if (window.solana.isConnected) {
                    const solanaAddress = window.solana.publicKey.toString();
                    this.solanaAddress = window.solana.publicKey.toString();
                    this.shortenedAddress = `${solanaAddress.substring(0, 2)}...${solanaAddress.substring(solanaAddress.length - 1)}`;
                } else {
                    return router.push({ name: 'Home' });
                }

            } catch (err) {
                console.error('Failed to connect Solana wallet:', err);
            }
        } else {
            alert('Please install the Phantom wallet extension.');
        }
    }
}