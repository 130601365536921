<script setup>
import { ref, onMounted, computed } from 'vue';
import User from '../models/User';
import { useRouter } from 'vue-router';

const router = useRouter();
const userInstance = new User();

const solanaAddress = ref('');
const userExists = ref(false);
let userNonce = null;
const isLoading = ref(true);
const usernameError = ref('');
const username = ref('');

const getProvider = () => {
  if ('phantom' in window) {
    const provider = window.phantom?.solana;
    if (provider?.isPhantom) {
      return provider;
    }
  }
  return null;
};

const shortenedAddress = computed(() => {
  if (solanaAddress.value) {
    return `${solanaAddress.value.substring(0, 4)}...${solanaAddress.value.substring(solanaAddress.value.length - 4)}`;
  }
  return '';
});

const checkUser = async () => {
  try {
    userNonce = await userInstance.getNonceByAddress(solanaAddress.value);
    if (userNonce != null) {
      userExists.value = true;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  const provider = await getProvider();
  provider.on('accountChanged', () => {
    // Refresh the page to reflect the new account unless we are in dashboard  
    if (window.location.pathname != '/dashboard') {
      window.location.reload();
    }
  });
  if (window.solana && window.solana.publicKey) {
    solanaAddress.value = window.solana.publicKey.toString();
  }
  await checkUser();
  if (userExists.value == true) {
    await router.push({ name: 'Dashboard' });
  }
});

const register = async () => {
  // If the username input is empty, set usernameError to 'Username is required'
  if (!username.value) {
    usernameError.value = 'Username is required';
    return;
  }
  // Validate the username input with the regex ^[a-zA-Z0-9][a-zA-Z0-9._@#$&-]{2,28}[a-zA-Z0-9]$
  if (!/^[a-zA-Z0-9][a-zA-Z0-9._@#$&-]{2,28}[a-zA-Z0-9]$/.test(username.value)) {
    usernameError.value = 'Username must be between 4 and 30 characters and can only contain letters, numbers, and special characters . _ @ # $ & -';
    return;
  }
  try {
    const data = await userInstance.registerUser(username.value);
    userExists.value = true;
    userNonce = data.user.nonce;
    await login(userNonce);
  } catch (error) {
    if (error.message == "User rejected the request") {
      usernameError.value = "Failed to sign login message";
    }
    usernameError.value = error.message;
  }
};

const login = async () => {
  try {
    await userInstance.login(userNonce);
    await router.push({ name: 'Dashboard' });
  } catch (error) {
    usernameError.value = error.message;
  }
};

</script>

<template>
  <div id="app" class="flex flex-col h-full">
    <div class="flex-grow flex justify-center items-center p-8">
      <div class="container-login p-3 grid grid-cols-1">
        <span>
          <h1 class="text-lg">Wallet Connected</h1>
          <p id="output">{{ shortenedAddress }}</p>
          <h2 class="text-lg mt-5">Whalecum Ser</h2>
          <div v-if="!userExists" class="mt-8">
            <label class="flex flex-col relative focus group">
              <input type="text" name="username" v-model="username" required class="border-2 border-black px-4 pb-3 pt-8 leading-9 text-align: center"
                placeholder="Your username">
            </label>
            <p v-if="usernameError" class="text-red-500 mt-2">{{ usernameError }}</p>
          </div>
          <div v-if="!isLoading" class="grid grid-flow-col mt-7 gap-4">
            <button v-if="!userExists" @click="register" class="primary-button" style="text-shadow: 0 0 black;">
              REGISTER
            </button>
            <button v-if="userExists" @click="login" class="primary-button" style="text-shadow: 0 0 black;">
              SIGN IN
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
